import React, {useContext} from 'react'
import "../scss/start.scss"
import {SoundsDispatchContext} from "../context/SoundsContext";
import Style from '../scss/popup.module.scss';

const Startscreen = props => {

  const setSounds = useContext(SoundsDispatchContext);

  return (
    <div className={Style.overlay}>
      <div className={Style.message}>
        <div className={Style.popupText}>This webpage would like to play sounds. This action can be reverted in the future.</div>
        <button
          className={Style.button}
          onClick={() => {
            props.onClick();
            setSounds({
              enabled: false
            })
          }}
        >
          Block
        </button>
        <button
          className={Style.button}
          onClick={() => {
            props.onClick();
            setSounds({
              enabled: true
            })

          }}
          >
          Allow
        </button>
      </div>
    </div>
  )
}


export default Startscreen
