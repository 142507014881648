import React, {
  useRef,
  useEffect,
  useState
} from "react"

const Confetti = () => {

  // Declare a new state variable, which we'll call "count"
  const {width, height} = useWindowSize(50);
  const pixelRatio      = typeof window !== 'undefined' ? window.devicePixelRatio : 1;
  const canvas          = useRef(null);
  const ctx             = useRef(null);
  const tick            = useRef(null);
  const items           = useRef([]);

  const style = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 2,
    pointerEvents: "none"
  };

  const startTime = new Date().getTime();
  let currentTime = startTime;

  let colors  = [
    '#0097ce',
    '#ff40b4',
    '#ff7276',
    '#ffab4d',
    '#ffe800',
    '#38d430',
  ];
  let index   = 150;

  let dw = Math.floor(pixelRatio * width);
  let dh = Math.floor(pixelRatio * height);

  const particle= (x, y, i, minv) => {
    const angle = Math.random()*(Math.PI*2);
    const amount = Math.random()*15.0 + minv;
    const vx = Math.sin(angle) * amount;
    const vy = Math.cos(angle) * amount;

    return {
      x: x,
      y: y,
      vx: vx,
      vy: vy,
      width: (Math.random() * 15) + 8,
      height: (Math.random() * 20) + 8,
      color: colors[i % colors.length],
      circle: (Math.random() > 0.8),
      rotate: Math.random() * 180,
      direction: (Math.random() * 5) - 2.5,
      fallSpeed: (Math.random() / 10) + 0.1
    }
  }

  useEffect(() => {

    ctx.current = canvas.current.getContext("2d");
    ctx.current.save();
    ctx.current.scale(pixelRatio, pixelRatio);

    for(let i = 0; i < index; i++){
      items.current[i] = particle(width / -4, height / -4, i, 10);
    }

    const render = () => {
      ctx.current.clearRect(0, 0, width, height);
      const time = new Date().getTime();
      const delta = (time - currentTime) / (1000/60);
      currentTime = time;

      if(time - startTime > 1500){
        items.current[++index % 600] = particle(Math.random() * width, -20, index, 10)
      }

      items.current.forEach(function(item){
        item.vx *= (1.0 - 0.05*delta);
        item.vy += (delta * item.fallSpeed);
        item.vy /= 1.0 + 0.05*delta;
        item.x += (delta * item.vx);
        item.y += (delta * item.vy);
        item.rotate += (delta * item.direction);

        ctx.current.fillStyle = item.color;
        if(item.circle){
          ctx.current.beginPath();
          ctx.current.arc(item.x, item.y, item.width / 2, 0, 2*Math.PI);
          ctx.current.fill();
          ctx.current.stroke();
          ctx.current.closePath();
        } else {
          ctx.current.save();
          ctx.current.beginPath();
          ctx.current.translate(item.x, item.y);
          ctx.current.rotate(item.rotate * Math.PI / 180);
          ctx.current.fillRect(-item.width / 2, -item.height / 2, item.width, item.height);
          ctx.current.strokeRect(-item.width / 2, -item.height / 2, item.width, item.height);
          ctx.current.translate(-item.x, -item.y);
          ctx.current.restore();
          ctx.current.closePath();
        }
      });

      tick.current = window.requestAnimationFrame(render);
    }

    render()

    return () => window.cancelAnimationFrame(tick.current);

  }, [width]);

  return (
      width !== 'undefined' ?
      <canvas
        ref={canvas}
        width={dw}
        height={dh}
        style={style}
      />
      : null
    )
}

const debounce = (delay, fn) => {
  let timerId;

  return function (...args) {
    if (timerId) {
      clearTimeout(timerId);
    }

    timerId = setTimeout(() => {
      fn(...args);
      timerId = null;
    }, delay);
  };
};

// Hook
function useWindowSize(debounceTime) {
  const isClient = typeof window === 'object';
  const getSize = () => ({
    width: isClient ? window.innerWidth : undefined,
    height: isClient ? window.innerHeight : undefined
  });
  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return;
    }

    const handleResize = () => {
      setWindowSize(getSize());
    };

    let handleResizeFn = handleResize;

    if (debounceTime) {
      handleResizeFn = debounce(debounceTime, handleResize);
    }

    window.addEventListener('resize', handleResizeFn);

    return () => window.removeEventListener('resize', handleResizeFn);
  }, []);

  return windowSize;
}

export default Confetti;