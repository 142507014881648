import React, {useContext} from 'react'
import Attention from '../../static/attention.svg';
import Style from '../scss/popup.module.scss';
import Sound from 'react-sound';
import {SoundsContext} from "../context/SoundsContext";

export default (props) => {

  const sounds = useContext(SoundsContext);

  return !props.viewMessage2 ?
      (
        <>
      <Sound
        url="./message.mp3"
        playStatus={sounds.enabled?"PLAYING":"PAUSED"}
        autoload={sounds.enabled}
        loop={false}
      />
      <div className={Style.overlay}>
        <div className={Style.message}>
          <div className={Style.popupText}>
            <Attention style={{width: '2.5em'}}/><br/>
             Μήνυμα πολιτικής προστασίας!<br/>
            Κλείσε όλα τα παράθυρα και βοήθησε το τυχερό γατάκι να γλυτώσει από την πανδημία του Κορωνοϊού!<br/><br/>
            Civil protection message!<br/>
            Close all the pop up windows and help the lucky kitten escape the Coronavirus pandemic.
          </div>
          <button className={Style.button} onClick={()=> props.setViewMessage2(true)}>OK</button>
        </div>
      </div>
          </>
        )
      : null
}
