import React, { useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Windows } from "../components/windows"
import Startscreen from "../components/startscreen"
import Message from "../components/message"
import Cat from "../components/cat"
import Confetti from "../components/Confetti"


const IndexPage = () => {

  const [viewMessage, setViewMessage] = useState(false)
  const [viewMessage2, setViewMessage2] = useState(false)
  const [complete, setComplete] = useState(false)
  const [closed, setClosed] = useState([])
  const [reset, setReset] = useState(false)
  return (
    <Layout setClosed={setClosed} setComplete={setComplete} setReset={setReset}>
      <SEO
        title="Soul in Quarantine — Save the kitten"
        description="Το 2020 έκανε pop up στη ζωή σου και σε έκανε να κλάψεις; Κάνε έναν καλό απολογισμό και πάτα Χ!"
        image="meta_image.jpg"
      />
      <Cat complete={complete} reset={reset} setReset={setReset} closed={closed} viewMessage2={viewMessage2}/>
      <>
      {!viewMessage
          ? (<Startscreen onClick={() => setViewMessage(true) } />)
          : (<>
            {!viewMessage2
              ? (<Message viewMessage2={viewMessage2} setViewMessage2={setViewMessage2} />)
              : (<Windows
              closed={closed}
              setClosed={setClosed}
              complete={complete}
              setComplete={setComplete}
              setReset={setReset}
              />)
            }
          {
            complete && (<Confetti />)
          }
            </>)
      }
      </>
    </Layout>
  )
}

export default IndexPage
